body{ padding: 0px; margin: 0px; list-style: none; font: normal 14px/18px Arial; color:#000; background: #fff; }
p{padding: 0px; margin: 0px;}
a{padding: 0px; margin: 0px; outline: none !important; text-decoration: none !important;}
ul, ol{ list-style: none; padding: 0px; margin: 0px;}
ul li{ padding: 0px; margin: 0px;}
h1, h2, h3, h4, h5, h6{padding: 0px; margin: 0px;}
input[type="text"]::-webkit-input-placeholder { opacity: 1; }
input[type="text"]::-moz-input-placeholder { opacity: 1; }
input[type="search"]::-webkit-input-placeholder { opacity: 1; }
input[type="search"]::-moz-input-placeholder { opacity: 1; }
input[type="email"]::-webkit-input-placeholder { opacity: 1; }
input[type="email"]::-moz-input-placeholder { opacity: 1; }
input[type="tel"]::-webkit-input-placeholder { opacity: 1; }
input[type="tel"]::-moz-input-placeholder { opacity: 1; }
textarea::-moz-input-placeholder { opacity: 1; }
textarea::-webkit-input-placeholder { opacity: 1; }
a:hover{ outline: none !important; text-decoration: none !important;}
a:active{ outline: none !important;text-decoration: none !important;}
input[type="submit"]{cursor: pointer;}
input[type="text"]{outline: none;}
input[type="email"]{outline: none;}
input[type="tel"]{outline: none;}
input textarea{outline: none; -webkit-appearance: none;}
input[type="search"]{outline: none;}
ul{display: inline-block;}
input{outline: none !important;}
textarea{outline: none !important;}
.pink{background-color:pink!important;}
#txtBtn {
  background: #e41e29;
  color: #fff;
  padding: 0 30px;
  height: 37px;
  font: 400 20.83px/37px 'Rubik', sans-serif;
  height: 37px;
  display: inline-block;
  border:0;
}
.ng-dropdown-panel .scroll-host {
  min-width: 500px;
}

.ng-option-label {
  font-size: 16px !important;
}

.ng-select .ng-select-container {
  border: 1px solid #777777!important;width:237px!important;
}
/*font-family: 'Rubik', sans-serif;*/
.header_sec {
  width: 100%;
  padding: 5px 30px;
  display: inline-block;
}
.logo{width: auto; float: left;}
.navbar-brand{margin: 0px; padding: 0px; padding-top: 26px;}
.navbar-brand img{max-width: 100%;}
.hdr_rt{width: auto; float: right;}
.hdr_rt ul li{float: left; border-right: 1px solid #f1f1f1; font: 400 20.83px/25px 'Rubik', sans-serif; text-align: center;   }
.hdr_rt ul li:last-child{border: 0px;}
.hdr_rt ul li a{color: #5e5e5e; padding: 25px 65px 17px; display: inline-block;}
.hdr_rt ul li:last-child a{padding: 25px 45px 17px; }
.hdr_rt ul li span{width: 100%; display: inline-block; padding-top: 9px;}
.hdr_rt ul li i{width: 100%; display: inline-block;}
.main_container{width: 100%; background: #ebeff3; padding: 30px; display: inline-block; }
.top_hding{width: 100%; background: #e41e29; }
.top_hding h1{font: 700 37.5px/40px 'Rubik', sans-serif; color: #fff; padding: 15px 23px;}
.tab_link{width: 100%; margin-top: 17px; padding: 10px 0; background: #fff; }
.tab_link ul li{float: left; font: 400 22.99px/28px 'Rubik', sans-serif; border-right: 1px solid #7d7f81;}
.tab_link ul li a{padding: 17px 27px; color: #7d7f81; display: inline-block; }
.tab_link ul li.actv a{color: #e41e29;}
.tab_link ul li:last-child{border: 0px;}
.tab_link.hed ul li{color: #7d7f81; font: 400 22.29px/28px 'Rubik', sans-serif;}


.tab_link ul li a:hover{color: #e41e29;}
.rma_histry_form{width: 100%; background: #fff; padding: 15px 20px; display: inline-block; margin-top: 18px; }
.rma_histry_form h2{color: #7d7f81; font: 700 29.37px/35px 'Rubik', sans-serif; }
.rma_histry_form h3{margin-top: 15px; position: relative; font: 500 22.92px/28px 'Rubik', sans-serif; color: #7d7f81; }
.rma_histry_form h3:after{width: 100%; position: absolute; top: 45%; right: 0; height: 3px; background: #ebeff3; content: ""; z-index: 0;}
.rma_histry_form h3 span{display: inline-block; padding-right: 20px; background: #fff; position: relative; z-index: 99; }
.rma_search{width: 100%; padding: 18px 0 30px; border-bottom: 3px solid #ebeff3; display: inline-block;}
.rma_search ul{width: 100%;}
.rma_search ul li{float: left; padding-right: 9px; padding-bottom: 9px;}
.rma_search ul li span{display: inline-block; padding-right: 8px; color: #7d7f81; font: 400 18.83px/25px 'Rubik', sans-serif; }
.rma_search ul li em{font-style: normal; display: inline-block;}
.rma_search ul li em input[type="text"], .rma_search ul li em select{width: 228px;height: 37px;border: 1px solid #777777;outline: none;color: #b3b3b3;
padding: 0 6px;font: 400 20.83px/34px 'Rubik', sans-serif;outline: none;}
.rma_search ul li em input[type="text"]::placeholder, .rma_search ul li em select::placeholder{opacity: 1; color: #b3b3b3; }
.rma_search ul li .datepicker{background: url(../images/clnderIcn.png) no-repeat left 13px center; text-align: right; }
.rma_search ul li em select{-webkit-appearance: none; background: url(../images/drpArw.png) no-repeat right center; outline: none; }
.rma_search ul li input[type="submit"]{width: 195px; height: 37px; text-align: center; border: 0px; outline: none;
color: #fff; background: #e41e29; font: 400 20.83px/25px 'Rubik', sans-serif; }
.rma_search ul li:last-child{padding-right: 0px; padding-left: 20px; }
.auto {
  -webkit-appearance: none;
  background: url(../images/drpArw.png) no-repeat right center;
  outline: none;
  min-width:237px;
  
}
.easy-autocomplete{
  min-width:190px!important;
}
.easy-autocomplete-container ul li {
  float: none !important;
  width: auto !important;
}

 

.table_form{width: 100%; }
.table_hed{width: 100%; margin-bottom: 10px; padding-top: 15px; }
.table_hed ul li{padding: 0 2px; width: 20%; float: left;}
.table_hed ul{width: 100%;}
.table_hed .dropdown-menu{width: 100%; }
.table_hed .dropdown-toggle{color: #fff; background: #7d7f81 url(../images/drpArw2.png) no-repeat right 40px center; padding: 12px 20px; 
font: 400 20.83px/30px 'Rubik', sans-serif; cursor: pointer;  height: 56px;}
.table_hed .dropdown-toggle:after{position: inherit; background: none; content: none; }
.table_hed .dropdown-menu .dropdown-item:hover{background: #7d7f81 !important; color: #fff !important; }
.table_hed ul li:last-child .dropdown-toggle{background: #7d7f81; cursor: inherit; }



.table_contn{width: 100%;}
.table_contn tr td{ color: #7d7f81; font: 400 20.83px/32px 'Rubik', sans-serif; text-align: center; }
.SilicomH {
 min-width: 1300px;
}
 
.clientH tr td{
  padding:18px;
}

.table_contn table {
  width: 100%;
}
.table_contn tr td a.link{color: #fff;
background: #e41e29;
font: 400 20.83px/64px 'Rubik', sans-serif; display: inline-block; width: 100%; text-align: center; }
.table_contn tr:nth-child(even) {background: #f4f4f4;}
.table_contn tr td:last-child{padding: 0px; border: 0px; }
.table_contn tr td{border: 3px solid #fff; }
.table_contn table{border: 3px solid #fff;}
.table_contn tr td:last-child{width: 15%;}
.table_contn tr td:nth-child(4){width: 25%;}


/*.table_scroll{padding-right: 20px;}*/
.table_contn .js-scroll-rail{border-radius: 0px !important; background: #7d7f81 !important; width: 19px !important; }
.table_contn .js-scroll-bar{border-radius: 0px !important; background: #fff !important; width: 13px !important; right: 3px !important; }

.rma_search.issues.input h3{margin-bottom: 25px;}

.tab_link.hed{padding: 15px;}
.rma_histry_form.component .rma_search ul li{width: 100%;}
.rma_histry_form.component .rma_search ul li span{width: 56%; float: left;}
.rma_histry_form.component .rma_search ul li em{width: 44%; float: left;}
.rma_histry_form.component .rma_search ul li:last-child{padding-left: 0px;}
.rma_histry_form.component .rma_search ul li:last-child input[type="text"]{width: 45px;}

.rma_search_brd h3{margin-bottom: 17px; }
.rma_search.issues li:last-child span{width: 100% !important; padding-bottom: 10px;}
.rma_search.issues li textarea{width: 665px; height: 155px; outline: none !important; border: 1px solid #777777; padding: 15px; resize: none;
-webkit-appearance: none; font: 400 14px/20px 'Rubik', sans-serif;}
.rma_search.issues label{font: 500 20.83px/25px 'Rubik', sans-serif; color: #7d7f81; width: 100%; display: inline-block; padding-bottom: 5px; }
.rma_search.issues.input ul li span{float: left; padding: 0px; width: 80% !important; border-right: 3px solid #fff; padding: 17px;  }
.rma_search.issues.input ul li em{width: 20%;  text-align: center;   }
.rma_search.issues.input ul li:nth-child(odd) { background: #f4f4f4;}
.rma_search.issues.input ul li{padding: 0px; text-transform: uppercase;  }
.rma_search.issues.input ul{width: 365px; }
.rma_search.issues.input ul li em a{display: inline-block; padding: 12px 15px; width: 100%; height: 100%;}
.rma_search.issues.input ul li em a img{max-width: 100%; }
.file_input input[type="file"]{display: none;}
.file_input .inputfile + label{width: 257px; padding: 0px; margin-top: 20px; height: 37px; color: #fff !important; background: #e41e29; padding: 0 25px;
font: 400 20.83px/37px 'Rubik', sans-serif;}
.file_input .inputfile-2 + label{border: 0px; margin-bottom: 0px; }
.file_input .inputfile + label img{margin-right: 10px; }
.file_submit{width: 100%; padding: 80px; text-align: center;}
.file_submit ul{font-size: 0px;}
.file_submit ul li{display: inline-block;padding: 0 9px !important;width: auto !important;text-transform: capitalize !important;background: none !important;}
.file_submit ul li input[type="submit"]{color: #fff;padding: 0 25px;display: inline-block;background: #e41e29;
font: 400 29.62px/53px 'Rubik', sans-serif;border: 0px;outline: none;height: inherit;width: auto;}
.file_submit ul li input[type="reset"]{color: #fff; padding: 0 30px; display: inline-block; background: #7d7f81; 
font: 400 29.62px/53px 'Rubik', sans-serif; border: 0px; outline: none; cursor: pointer; }


.chek_rdio{width: 100%; padding-top: 14px;}
.chek_rdio input[type=radio] { display:none; margin:10px;}
.chek_rdio input[type=radio] + label {color: #7d7f81; font: 400 20.83px/25px 'Rubik', sans-serif; padding-left: 35px; padding-right: 10px;
background: url(../images/chek.png) no-repeat left center; }
.chek_rdio input[type=radio]:checked + label {color: #7d7f81; font: 400 20.83px/25px 'Rubik', sans-serif; padding-left: 35px; padding-right: 10px;
background: url(../images/chekd.png) no-repeat left center; }

.chek_rdio input[type="checkbox"] {display: none;}
.chek_rdio input[type="checkbox"] + label {color: #7d7f81; font: 400 17px/23px 'Rubik', sans-serif; padding-left: 35px; padding-right: 10px;
background: url(../images/chek.png) no-repeat left center; }
.chek_rdio input[type="checkbox"]:checked + label {color: #7d7f81; font: 400 17px/23px 'Rubik', sans-serif; padding-left: 35px; padding-right: 10px;
background: url(../images/chekd.png) no-repeat left center; }

.rma_search_brd{width: 100%; padding: 18px 0 30px; border-bottom: 3px solid #ebeff3;}
.rma_search_brd .rma_search{padding: 0px; border: 0px; }
.rma_search.compont{width: 550px;}


.rma_search_brd.details .rma_search ul li:last-child span{width: 55% !important;}
.rma_search_brd.details .rma_search ul li:last-child input[type="text"]{width: 237px;}
.rma_search_brd.details .rma_search ul li:nth-child(3) span{padding-bottom: 8px;}


.rma_search.method ul li{float: left; width: auto !important; }
.rma_search.method ul li span{width: auto !important; float: none !important; }
.rma_search.method ul li em{width: auto !important; float: none !important;}


.delivery_dtail {width: 800px;}
.delivery_dtail ul li{color: #7d7f81; font: 400 17px/23px 'Rubik', sans-serif; }
.delivery_dtail ul li strong{font-weight: 700; width: 30%; float: left;}
.rma_histry_form.component .delivery_dtail ul li span{width: 25% !important; float: left !important; padding: 0px; }
.rma_histry_form.component .delivery_dtail ul li .chek_rdio{width: 45% !important; float: left !important; padding: 0px;}
.rma_histry_form.component .rma_search.method .delivery_dtail ul li{width: 100% !important;}



.rma_src_lft{width: 60%; float: left; }
.rma_src_rt{width: 40%; float: left; }
.rma_src_lft ul li{width: 50%; float: left; }
.rma_src_lft ul li{font-size: 0px; }


.rma_src_lft ul li span{width: 35%; float: left;}
.rma_src_lft ul li em{width: 65%; float: left;}
.rma_src_lft ul li{padding-bottom: 13px; }
.rma_src_lft ul li:last-child{padding-left: 0px;}
.rma_src_rt label{color: #7d7f81; font: 400 20.83px/25px 'Rubik', sans-serif; padding-right: 18px; width: 22%; float: left; }
.src_chek_box{width: 240px; border: 1px solid #777777; padding: 4px; float: left; display: inline-block;  }
.src_chek_box label{width: 100% !important;}
.rma_src_rt .rma_src_histry_btn a{width: 125px; height: 37px; text-align: center; color: #fff; background: #e41e29; font: 400 20.83px/37px 'Rubik', sans-serif;
display: inline-block;  }
.rma_src_histry_btn{width: 142px;height: 166px;float: left;display: flex;align-items: flex-end;padding-left: 35px;}


.table_contn.history tr td{font-size: 19.58px; }
.newRam tr td {
  font-size: 19.58px;
}
.table_hed.histry ul li{width: 7.69%; float: left; }
.table_hed.histry ul li:nth-child(2) .dropdown-toggle{background: #7d7f81; cursor: inherit; }
.table_hed.histry ul li:nth-child(3) .dropdown-toggle{background: #7d7f81; cursor: inherit; }
.table_hed.histry ul li:nth-child(6) .dropdown-toggle{background: #7d7f81; cursor: inherit; }
.table_hed.histry ul li:nth-child(7) .dropdown-toggle{background: #7d7f81; cursor: inherit; }
.table_hed.histry ul li:nth-child(9) .dropdown-toggle{background: #7d7f81; cursor: inherit; }
.table_hed.histry ul li:nth-child(10) .dropdown-toggle{background: #7d7f81; cursor: inherit; }
.table_hed.histry ul li:nth-child(11) .dropdown-toggle{background: #7d7f81; cursor: inherit; }
.table_hed.histry ul li:nth-child(12) .dropdown-toggle{background: #7d7f81; cursor: inherit; }
a[data-t="tooltip"] {
  color: #7d7f81 !important;
}
.table_contn.history tr td a {
  color: white;
}
.table_contn.history tr td:last-child a{color: #fff; }
.table_contn.history tr td a.link{line-height: 61px;}

.ui-tooltip{background: #e41e29 !important; color: #fff !important; width: 150px; height: 125px; box-shadow: inherit !important; border: 0px !important;
border-radius: 0px !important; font: 400 20.83px/25px 'Rubik', sans-serif; display: flex;  justify-content: center; align-items: center; 
flex-direction: column; text-align: center; position: relative; }
.ui-tooltip:after{display: inline-block;
width: 0;
height: 0;
content: "";
border-bottom: 14px solid;
border-right: 14px solid transparent;
border-top: 0;
border-left: 14px solid transparent; position: absolute; top: -13px; left: 20px; color: #e41e29;  }


.table_contn.history.mange_tble tr td:nth-child(10){padding: 0px; border-top: 0px;}
.table_contn.history.mange_tble tr td:nth-child(11){padding: 0px; border-top: 0px;}
.table_contn.history.mange_tble tr td:nth-child(10) a{background: #434446; }
.table_contn.history.mange_tble tr td:nth-child(4){font-size: 15px; padding-left: 0px; padding-right: 0px; }

.table_hed.histry.manage ul li{width: 8.33%; padding: 0px; text-align: center; }
.table_hed.histry.manage ul li:nth-child(2) .dropdown-toggle{background: #7d7f81 url(../images/drpArw2.png) no-repeat right 40px center;
cursor: pointer; }
.table_hed.histry.manage ul li:nth-child(3) .dropdown-toggle{background: #7d7f81 url(../images/drpArw2.png) no-repeat right 40px center;
cursor: pointer; }
.table_hed.histry.manage ul li:nth-child(4) .dropdown-toggle{background: #7d7f81; cursor: inherit;}
.table_hed.histry.manage ul li:nth-child(5) .dropdown-toggle{background: #7d7f81; cursor: inherit;}
.table_hed.histry.manage ul li:nth-child(6) .dropdown-toggle{background: #7d7f81 url(../images/drpArw2.png) no-repeat right 40px center;
cursor: pointer; }
.table_hed.histry.manage ul li:nth-child(7) .dropdown-toggle{background: #7d7f81 url(../images/drpArw2.png) no-repeat right 40px center;
cursor: pointer; }
.table_hed.histry.manage ul li:nth-child(8) .dropdown-toggle{background: #7d7f81; cursor: inherit;}
.table_hed.histry.manage ul li:nth-child(9) .dropdown-toggle{background: #7d7f81; cursor: inherit;}
.rma_histry_form h6 em{color: #7d7f81; display: inline-block; padding-right: 17px; font: 400 20.83px/25px 'Rubik', sans-serif; }
.rma_histry_form h6 span{display: inline-block;}
.rma_histry_form h6 span a{color: #fff; display: inline-block; font: 400 20.83px/37px 'Rubik', sans-serif; height: 37px; background: #e41e29; 
text-align: center; width: 180px; }
.rma_histry_form h6{padding-top: 14px; padding-bottom: 12px;}



.add_user input[type="checkbox"] {display:none;}
.add_user input[type="checkbox"] + label {color: #7d7f81;
font: 400 17px/23px 'Rubik', sans-serif;
padding-left: 35px;
padding-right: 10px;
background: url(../images/chek.png) no-repeat left center;}
.add_user input[type="checkbox"]:checked + label {color: #7d7f81;
font: 400 17px/23px 'Rubik', sans-serif;
padding-left: 35px;
padding-right: 10px;
background: url(../images/chekd.png) no-repeat left center;}


.new_user{width: 100%; margin-top: 45px; }
.new_user h3{position: relative; font: 500 22.92px/28px 'Rubik', sans-serif; color: #7d7f81; margin-bottom: 14px; }
.new_user h3 span{display: inline-block;padding-right: 20px;background: #fff;position: relative;z-index: 99;}
.user_frm input[type="password"], .user_frm input[type="email"]{width: 237px;
height: 37px;
border: 1px solid #777777;
outline: none;
color: #b3b3b3;
padding: 0 6px;
font: 400 20.83px/34px 'Rubik', sans-serif;}
.user_btn{display: flex;justify-content: flex-start;align-items: flex-end;height: 140px; }
.user_btn a{width: 210px;
height: 45px;
text-align: center;
color: #fff;
background: #e41e29;
font: 400 20.83px/45px 'Rubik', sans-serif;
display: inline-block;}
.user_frm {padding: 0px; border: 0px;}
.user_frm ul li{padding-bottom: 14px;}
.user_frm ul li span{width: 38%; float: left;}
.user_frm ul li em{width: 62%; float: left;}
.user_frm ul li:last-child{padding-left: 0px;}
.user_frm ul li{width: 100%;}
.user_frm ul li label{font-size: 20.53px !important; line-height: 24px !important; }

.table_contn.history.mange_tble tr td{width: 8.33%;}



.table_contn input[type="checkbox"] {display: none;}
.table_contn input[type="checkbox"] + label {margin: 0px; padding: 0px; width: 24px; height: 24px; font: inherit;}
.table_contn input[type="checkbox"]:checked + label {margin: 0px; padding: 0px; width: 24px; height: 24px; font: inherit;}
.table_contn .chek_rdio{padding: 0px;}
.table_contn.history.status.rmaAdd tr td:nth-child(9) {
  padding: 0px;
  border-top: 0px;
  
}
.table_contn.history.status.rmaAdd tr td:nth-child(8) {
  padding: 0px;
  border-top: 0px;
}
.table_contn.history.status tr td:nth-child(9){padding: 0px; border-top: 0px;}
.table_contn.history.status tr td:nth-child(9) .link{background: #434446; }
.table_contn.history.status tr td{width: auto;}
.table_contn.history.status tr td:nth-child(9){width: 6%;}
.table_contn.history.status tr td:last-child{width: 6%;}
.table_hed.histry.status ul li{width: 10%; float: left; }
.table_hed.histry.status ul li:last-child{padding-left: 0px; width: 6%;}
.table_hed.histry.status ul li:nth-child(9){padding-right: 0px; width: 6%;}

.send_approv{width: 100%; padding-top: 25px; text-align: right; }
.send_approv a{width: 210px; height: 37px; color: #fff; font: 400 20.83px/37px 'Rubik', sans-serif; background: #e41e29; display: inline-block; 
text-align: center;  }

/*.table_contn.history.status tr td:nth-child(2){width: 14%;}
.table_contn.history.status tr td:nth-child(3){width: 14%;}
.table_hed.histry.status ul li:nth-child(2){width: 14%;}
.table_hed.histry.status ul li:nth-child(3){width: 14%;}*/


.rma_detail_frm{width: 100%; }
.rma_detail_frm{width: 100%; }
.rma_dtail_input{width: 100%; margin-top: 13px; padding: 0px; border: 0px; }
.rma_dtail_input li{width: 100%; padding-bottom: 14px; display: inline-block; }
.rma_dtail_input li span{width: 30%; float: left; display: inline-block;  padding-top: 8px; color: #7d7f81; font: 400 20.83px/25px 'Rubik', sans-serif;}
.rma_dtail_input li em{width: 70%; float: left; display: inline-block;}
  .rma_dtail_input li em input[type="password"],.rma_dtail_input li em input[type="text"], .rma_dtail_input li em input[type="email"], .rma_dtail_input li em input[type="tel"] {
    width: 265px;
    height: 37px;
    border: 1px solid #777777;
    outline: none;
    padding: 0 13px;
    font: 400 14px/37px 'Rubik', sans-serif;
  }
.rma_dtail_input li span i{color: #e41e29; font-style: normal; }
.rma_dtail_input ul{width: 100%;}
.rma_detail_frm .col-sm-5 .rma_dtail_input ul li span{width: 100%; padding: 0px; padding-bottom: 5px; }
.rma_dtail_input li em textarea{width: 660px; height: 150px; border: 1px solid #777777; outline: none; padding: 13px; 
font: 400 14px/20px 'Rubik', sans-serif; resize: none; }
.rma_dtail_input .trch_num{width: 490px; height: 37px; text-align: center; display: inline-block; color: #fff; background: #7d7f81; 
font: 400 20.83px/37px 'Rubik', sans-serif; margin: 10px 0 12px}
.rma_dtail_input .chek_rdio{width: 100%;margin: 0px; padding: 0px; }
.rma_dtail_input ul li:last-child{padding-left: 0px;}

.produ_add_btn{width: 100%; text-align: right; padding-top: 15px; border-top: 3px solid #ebeff3; display: inline-block;}
.produ_add_btn ul li{padding: 0 4px; float: left; }
.produ_add_btn ul li a{background: #e41e29; color: #fff; padding: 0 30px; height: 37px; font: 400 20.83px/37px 'Rubik', sans-serif; height: 37px; 
display: inline-block; }


.mrStatus .col-sm-5 .rma_dtail_input ul li em{width: 100%;}

.table_hed.histry.status ul li:nth-child(2) .dropdown-toggle{background: #7d7f81 url(../images/drpArw2.png) no-repeat right 40px center; cursor: pointer;}
.table_hed.histry.status ul li:nth-child(3) .dropdown-toggle{background: #7d7f81 url(../images/drpArw2.png) no-repeat right 40px center; cursor: pointer;}
.table_hed.histry.status ul li:nth-child(4) .dropdown-toggle{background: #7d7f81; cursor: inherit; }
.table_hed.histry.status ul li:nth-child(5) .dropdown-toggle{background: #7d7f81; cursor: inherit; }
.table_contn.history.status tr td:nth-child(4){font-size: 0px; padding: 12px;}
.table_contn.history.status tr td:nth-child(5){font-size: 0px; padding: 12px;}
.table_contn.history.status tr td:nth-child(6){font-size: 0px; padding: 12px;}
.table_contn.history.status tr td:nth-child(7){font-size: 0px; padding: 12px;}

.produ_add_btn span{width: auto; float: left; color: #7d7f81; font: 400 20.83px/25px 'Rubik', sans-serif;}
.produ_add_btn ul{width: auto; float: right;}


.table_hed.newRam ul li{width: 7.14%; float: left;}



.details_hding{padding-top: 65px !important; background: #fff; padding-left: 15px; padding-right: 15px;}
.details_hding h5{color: #7d7f81; font: 400 20.83px/25px 'Rubik', sans-serif; padding-bottom: 15px;}


.table_hed.newRam.btm ul li{width: 11.11% !important;}
.table_contn.newRam.btm tr td{width: 11.11% !important;}
#newRam ul li .dropdown-toggle{cursor: inherit !important; background: #7d7f81 !important; }
#newRam ul li:nth-child(4) .dropdown-toggle{background: #7d7f81 url(../images/drpArw2.png) no-repeat right 40px center !important; cursor: pointer !important;}
#newRam ul li:nth-child(5) .dropdown-toggle{background: #7d7f81 url(../images/drpArw2.png) no-repeat right 40px center !important; cursor: pointer !important;}
#newRam ul li:nth-child(6) .dropdown-toggle{background: #7d7f81 url(../images/drpArw2.png) no-repeat right 40px center !important; cursor: pointer !important;}
#newRam tr td:last-child{padding: 18px;}


.table_hed.newRam ul li .dropdown-toggle{cursor: inherit !important; background: #7d7f81 !important; }
.table_hed.newRam ul li:nth-child(1) .dropdown-toggle{background: #7d7f81 url(../images/drpArw2.png) no-repeat right 40px center !important; 
cursor: pointer !important; }
.table_hed.newRam ul li:nth-child(3) .dropdown-toggle{background: #7d7f81 url(../images/drpArw2.png) no-repeat right 40px center !important; 
cursor: pointer !important; }
.table_hed.newRam ul li:nth-child(4) .dropdown-toggle{background: #7d7f81 url(../images/drpArw2.png) no-repeat right 40px center !important; 
cursor: pointer !important; }
.table_hed.newRam ul li:nth-child(9) .dropdown-toggle{background: #7d7f81 url(../images/drpArw2.png) no-repeat right 40px center !important; 
cursor: pointer !important; }

.table_contn.newRam tr td{width: 7.14% !important; }

.new_ram_frm {border: 0px; }
.delivery_dtail ul li strong a{color: #7d7f81; }
.delivery_dtail.rma_search.rt_ram {border: 0px; padding: 0px;}

.table_form.top{padding: 15px; background: #fff;}
.table_form.btm{padding: 15px; background: #fff;}


.rma_dtail_input.rma_search.fixed_width ul{width: 380px; }
.rma_dtail_input.rma_search.fixed_width ul li span{width: 170px !important; }
.rma_dtail_input.rma_search.fixed_width ul li em{width: 200px !important;}
.rma_dtail_input.rma_search.fixed_width ul li em input[type="text"]{width: 100% !important;}
.rma_dtail_input.rma_search.fixed_width ul li em select{width: 100% !important;}
.rma_dtail_input.rma_search.fixed_width .trch_num{width: 100% !important;}

.table_hed.newRam.filtr_Tp ul li .dropdown-toggle{background: #7d7f81 !important; cursor: inherit !important;}
.table_hed.newRam.filtr_Tp ul li{width: 7.14% !important; }
#newRam2 ul li .dropdown-toggle{background: #7d7f81 !important; cursor: inherit !important;}
.table_hed.fixed_width ul li .dropdown-toggle{background: #7d7f81 !important; cursor: inherit !important;}


.table_contn.line_hgh tr td{line-height: 25px;}


.table-bordered tr th{font: 400 17px/30px 'Rubik', sans-serif; background: #7d7f81; height: 56px; color: #fff; padding: 12px 7px;text-align:center}


 
.table_contn tr th{border: 3px solid #fff;white-space:nowrap;}
/*.table_contn.status tr th{width: 10%;}*/
/*.table_contn.status tr td{width: 10% !important;}*/
.table_contn tr td a.link{font-size: 17px;cursor:pointer;}
.table_contn tr td a.link:hover {
 color:wheat!important;
}














@media (min-width: 576px) and (max-width: 5000px) {
.main_head_in .dropdown .dropdown-menu { -webkit-transition: all 0.3s; -moz-transition: all 0.3s; -ms-transition: all 0.3s;
    -o-transition: all 0.3s;transition: all 0.3s; max-height: 0; display: block; opacity: 0;visibility: hidden; }

.main_head_in .dropdown:hover .dropdown-menu {max-height: 300px;opacity: 1; visibility: visible; }

} 


@media (min-width: 4000px) and (max-width: 8000px) {


.rma_dtail_input .trch_num {width: 670px;}


}


@media only screen and (max-width: 3000px){


.table_form.top {overflow: scroll !important;}
 





}



@media only screen and (max-width: 2500px){

 
.table_form { overflow: scroll !important;}
.table_contn.history tr td { font-size: 16px;}
  .newRam tr td {
    font-size: 17px;
  }
  .table_hed .dropdown-toggle {
    font-size: 17px;
  }
 

/*.table_contn.history.mange_tble tr td:first-child{width: 8%;}
.table_contn.history.mange_tble tr td:nth-child(2){width: 4%;}
.table_contn.history.mange_tble tr td:nth-child(3){width: 4%;}
.table_contn.history.mange_tble tr td:nth-child(4){width: 11%;}
.table_contn.history.mange_tble tr td:nth-child(5){width: 9%;}
.table_contn.history.mange_tble tr td:nth-child(6){width: 6%;}
.table_contn.history.mange_tble tr td:nth-child(7){width: 6%;}
.table_contn.history.mange_tble tr td:nth-child(8){width: 8%;}
.table_contn.history.mange_tble tr td:nth-child(9){width: 8%;}
.table_contn.history.mange_tble tr td:nth-child(10){width: 4%;}
.table_contn.history.mange_tble tr td:nth-child(11){width: 4%;}
.table_contn.history.mange_tble tr td:nth-child(12){width: 7%;}*/


.rma_search.index input[type="submit"]{width: 150px; font-size: 17px; }
.rma_search.index ul li:last-child{padding-left: 0px;}






}

@media (min-width: 1600px) and (max-width: 1799px) {

.rma_src_lft ul li span{font-size: 18px; }
.rma_search ul li em input[type="text"], .rma_search ul li em select{font-size: 18px; }

.user_frm ul li span{font-size: 19px; }


.rma_dtail_input li em textarea {width: 600px;}
.rma_search.method.new_ram_frm .delivery_dtail{width: 100% !important; }
.rma_search.method.new_ram_frm .delivery_dtail ul li span{width: 20% !important;}
.rma_search.method.new_ram_frm .delivery_dtail .chek_rdio { width: 30% !important;}

.rma_dtail_input .trch_num { width: 390px;}


}


@media (min-width: 1400px) and (max-width: 1599px) {

.rma_src_lft ul li span{font-size: 18px; }
.rma_src_lft ul li em input[type="text"], .rma_src_lft ul li ul li em select{font-size: 18px; }
.rma_src_rt label{font-size: 18px;}
.rma_src_lft ul li em{width: 62%;}
.rma_src_lft ul li span{width: 38%;}

.rma_search ul li span{font-size: 18px;}
.user_frm ul li label{font-size: 18px !important;}
.user_frm ul li select{font-size: 18px !important; }


.user_frm ul li span{font-size: 17px; }
.user_btn a{font-size: 17px; width: 180px;}
.user_frm ul li label{font-size: 17px !important;}


.rma_dtail_input li em textarea {width: 500px;}
.rma_dtail_input li em input[type="text"], .rma_dtail_input li em input[type="email"], .rma_dtail_input li em input[type="tel"]{width: 200px;}
.rma_dtail_input li span{font-size: 17px; padding-top: 7px;}


.rma_search.method.new_ram_frm .delivery_dtail{width: 100% !important; }
.rma_search.method.new_ram_frm .delivery_dtail ul li span{width: 23% !important;}
.rma_search.method.new_ram_frm .delivery_dtail .chek_rdio { width: 30% !important;}


.rma_search.index ul li{width: 20%; float: none; display: inline-block; padding: 0 10px; }
.rma_search.index ul li:last-child{text-align: center; width: 100%; padding: 10px 0 0 0; }
.rma_search.index ul{text-align: center; font-size: 0px;}
.rma_search.index ul li span{text-align: left; width: 100%; }
.rma_search.index ul li input[type="text"], .rma_search.index ul li select{width: 100%;}
.rma_search.index ul li em{width: 100%;}


}


@media (min-width: 1200px) and (max-width: 1399px) {

.hdr_rt ul li a {padding: 20px 30px !important;}


.rma_src_lft ul li span{font-size: 18px; }
.rma_src_lft ul li em input[type="text"], .rma_src_lft ul li ul li em select{font-size: 18px; }
.rma_src_rt label{font-size: 18px; width: 15%;}
.rma_src_lft ul li em{width: 62%;}
.rma_src_lft ul li span{width: 38%;}
.rma_src_lft {width: 80%;  float: left;}
.rma_src_rt {width: 100%;  float: left; padding-top: 20px;}


.rma_search ul li span{font-size: 18px;}
.user_frm ul li label{font-size: 18px !important;}
.user_frm ul li select{font-size: 18px !important; }

.user_frm ul li span{font-size: 17px; }
.user_btn a{font-size: 17px; width: 180px;}
.user_frm ul li label{font-size: 17px !important;}
.new_user .col-sm-4{max-width: 40%; flex: 0 0 40%;}
.new_user .col-sm-4:last-child{max-width: 20%; flex: 0 0 20%;}
.rma_histry_form h6 span a{font-size: 18px; }
.rma_histry_form h6 em{font-size: 18px; }


.rma_dtail_input li em textarea {width: 400px;}
 .rma_dtail_input li em input[type="password"],.rma_dtail_input li em input[type="text"], .rma_dtail_input li em input[type="email"], .rma_dtail_input li em input[type="tel"], .rma_dtail_input li em select {
    width: 170px !important;
    font-size: 16px !important;
  }
.rma_dtail_input li span{font-size: 16px !important; padding-top: 7px;}
.rma_dtail_input .trch_num{font-size: 17px; width: 274px;}

.delivery_dtail.rma_search.rt_ram {width: 100%;}


.rma_search.method.new_ram_frm .delivery_dtail{width: 100% !important; }
.rma_search.method.new_ram_frm .delivery_dtail ul li span{ padding-right: 20px;}
.rma_search.method.new_ram_frm .delivery_dtail .chek_rdio {}
.rma_search.method.new_ram_frm .delivery_dtail ul li strong{ padding-right: 20px;}


.rma_dtail_input.rma_search.fixed_width ul { width: 340px;}
.rma_dtail_input.rma_search.fixed_width ul li em {width: 190px !important;}
.rma_dtail_input.rma_search.fixed_width ul li span {width: 140px !important;}


.rma_search.index ul li{width: 20%; float: none; display: inline-block; padding: 0 10px; }
.rma_search.index ul li:last-child{text-align: center; width: 100%; padding: 10px 0 0 0; }
.rma_search.index ul{text-align: center; font-size: 0px;}
.rma_search.index ul li span{text-align: left; width: 100%; }
.rma_search.index ul li input[type="text"], .rma_search.index ul li select{width: 100%;}
.rma_search.index ul li em{width: 100%;}


}

  
  
  
@media (min-width: 992px) and (max-width: 1199px) {


.hdr_rt ul li a {padding: 15px 30px;}
.hdr_rt ul li{font-size: 13px; line-height: 20px; }
.navbar-brand img {width: 175px;}
.hdr_rt ul li i img{max-width: 100%; width: 25px; }
.navbar-brand { padding-top: 21px;}
.top_hding h1{font-size: 25px; line-height: 30px; }
.tab_link ul li a { padding: 13px 20px;}
.tab_link ul li{font-size: 18px;}
.rma_histry_form h2{font-size: 23px;}
.rma_histry_form h3{font-size: 20px;}
.rma_search.issues label{font-size: 18px;}
.rma_search ul li{padding: 4px;}
.table_contn tr td a.link{font-size: 14px; line-height: 45px; }
.table_contn tr td{font-size: 14px; padding: 11px 15px; line-height: 37px;}
.table_hed .dropdown-toggle{font-size: 14px; line-height: 20px; height: 45px; background-position: right 15px center; }

.hdr_rt ul li a {padding: 20px 30px !important;}

.rma_search ul li span{font-size: 18px;}
.rma_search ul li em input[type="text"], .rma_search ul li em select{font-size: 18px;}
.chek_rdio input[type="radio"] + label{font-size: 18px;}
.chek_rdio input[type="radio"]:checked + label{font-size: 18px;}
.file_submit ul li input[type="submit"]{font-size: 20px; line-height: 50px;}
.file_submit ul li input[type="reset"]{font-size: 20px; line-height: 50px;}
.file_input .inputfile + label{font-size: 18px;}


.rma_src_lft ul li span{font-size: 18px; }
.rma_src_lft ul li em input[type="text"], .rma_src_lft ul li ul li em select{font-size: 18px; }
.rma_src_rt label{font-size: 18px; width: 19%;}
.rma_src_lft ul li em{width: 62%;}
.rma_src_lft ul li span{width: 38%;}
.rma_src_lft {width: 100%;  float: left;}
.rma_src_rt {width: 100%;  float: left; padding-top: 20px;}
.table_contn.history tr td a.link {line-height: 50px;}


.user_frm ul li span{font-size: 17px; }
.user_btn a{font-size: 17px; width: 180px;}
.user_frm ul li label{font-size: 17px !important;}
.new_user .col-sm-4{max-width: 50%; flex: 0 0 50%;}
.new_user .col-sm-4:last-child{max-width: 100%; flex: 0 0 100%;}
.rma_histry_form h6 span a{font-size: 18px; }
.rma_histry_form h6 em{font-size: 18px; }
.user_btn{display: inline-block; height: auto; }


.rma_dtail_input li em textarea {width: 400px;}
  .rma_dtail_input li em input[type="password"],.rma_dtail_input li em input[type="text"], .rma_dtail_input li em input[type="email"], .rma_dtail_input li em input[type="tel"], .rma_dtail_input li em select {
    width: 170px !important;
    font-size: 16px !important;
  }
.rma_dtail_input li span{font-size: 16px !important; padding-top: 7px;}
.rma_dtail_input .trch_num{font-size: 17px; width: 274px;}
.mrStatus .col-sm-3{max-width: 50%; flex: 0 0 50%; }
.mrStatus .col-sm-5{max-width: 50%; flex: 0 0 50%; }
.mrStatus .col-sm-4{max-width: 70%; flex: 0 0 70%; }


.table_contn.history.status tr td label{margin-top: 9px; }
.table_contn.history.status tr td:nth-child(4){padding: 1px;}
.table_contn.history.status tr td:nth-child(5){padding: 1px;}
.table_contn.history.status tr td:nth-child(6){padding: 1px;}
.table_contn.history.status tr td:nth-child(7){padding: 1px;}


.rma_search.method.new_ram_frm .delivery_dtail{width: 100% !important; }
.rma_search.method.new_ram_frm .delivery_dtail ul li span{padding-right: 20px; width: 30% !important;}
.rma_search.method.new_ram_frm .delivery_dtail .chek_rdio { width: auto !important;}
.rma_search.method.new_ram_frm .delivery_dtail ul li strong{width: auto !important; padding-right: 20px;}

.rma_dtail_input.width ul{width: 380px;}
.rma_dtail_input.width ul li span{width: 170px !important;}
.rma_dtail_input.width ul li em{width: 200px !important;}
.rma_dtail_input.width ul li input[type="text"], .rma_dtail_input.width ul li input[type="email"], .rma_dtail_input.width ul li input[type="tel"]{width: 100% !important;}



.rma_search.index ul li{width: 20%; float: none; display: inline-block; padding: 0 10px; }
.rma_search.index ul li:last-child{text-align: center; width: 100%; padding: 10px 0 0 0; }
.rma_search.index ul{text-align: center; font-size: 0px;}
.rma_search.index ul li span{text-align: left; width: 100%; }
.rma_search.index ul li input[type="text"], .rma_search.index ul li select{width: 100%;}
.rma_search.index ul li em{width: 100%;}



}

 
 
 
@media (min-width: 768px) and (max-width: 991px) {


.hdr_rt ul li a {padding: 8px 15px;}
.hdr_rt ul li{font-size: 13px; line-height: 20px; }
.navbar-brand img {width: 130px;}
.hdr_rt ul li i img{max-width: 100%; width: 20px; }
.hdr_rt ul li span { padding-top: 5px;}
.navbar-brand { padding-top: 14px;}
.top_hding h1{font-size: 20px;line-height: 25px;padding: 11px 15px;}
.tab_link ul li a { padding: 8px 15px;}
.tab_link ul li{font-size: 15px;}
.rma_histry_form h2{font-size: 20px; line-height: 25px;}
.rma_histry_form h3{font-size: 19px; line-height: 24px; margin-top: 10px;}
.rma_search.issues label{font-size: 17px;}
.rma_search ul li{padding: 4px;}
.table_contn tr td a.link{font-size: 14px; line-height: 45px; }
.table_contn tr td{font-size: 14px; line-height: 37px;}
.table_hed .dropdown-toggle{font-size: 14px; line-height: 20px; height: 45px; background-position: right 15px center; }
.main_container { padding: 20px 15px;}
.tab_link { padding: 7px 0;}
.rma_search ul li span{font-size: 15px; }
.rma_search ul li em input[type="text"], .rma_search ul li em select{font-size: 13px; }
.rma_search{padding: 17px 0; }

.delivery_dtail {width: 100%;}
.rma_search ul li input[type="submit"]{font-size: 18px;}

.hdr_rt ul li a {padding: 8px 20px !important;}
.header_sec {padding: 5px 15px;}

.file_submit ul li input[type="submit"]{font-size: 20px; line-height: 45px;}
.file_submit ul li input[type="reset"]{font-size: 20px; line-height: 45px;}
.file_input .inputfile + label{font-size: 18px;}
.chek_rdio input[type="radio"] + label{font-size: 15px;}
.chek_rdio input[type="radio"]:checked + label{font-size: 15px;}
.file_submit { padding: 60px 0;}

.tab_link.hed ul li{font-size: 19px;}


.rma_src_lft ul li span{font-size: 17px; }
.rma_src_lft ul li em input[type="text"], .rma_src_lft ul li em select{font-size: 17px; width: 180px;}
.rma_src_rt label{font-size: 17px; width: 19%;}
.rma_src_lft ul li em{width: 62%;}
.rma_src_lft ul li span{width: 38%;}
.rma_src_lft {width: 100%;  float: left;}
.rma_src_rt {width: 100%;  float: left; padding-top: 20px;}
.table_contn.history tr td a.link {line-height: 50px;}
.rma_src_rt .rma_src_histry_btn a{font-size: 17px;}
.table_contn.history tr td {font-size: 16px;}
  .newRam tr td {
    font-size: 16px;
  }


.user_frm ul li span{font-size: 17px; }
.user_btn a{font-size: 15px;width: 150px;line-height: 40px;height: 40px;}
.user_frm ul li label{font-size: 17px !important;}
.new_user .col-sm-4{max-width: 50%; flex: 0 0 50%;}
.new_user .col-sm-4:last-child{max-width: 100%; flex: 0 0 100%;}
.rma_histry_form h6 span a{font-size: 17px; }
.rma_histry_form h6 em{font-size: 17px; }
.user_btn{display: inline-block; height: auto; }
.user_frm ul li span{width: auto; padding-right: 15px;}
.user_frm ul li em{width: auto;}
.user_frm ul li input[type="text"], .user_frm ul li input[type="email"], .user_frm ul li input[type="password"], .user_frm ul li select{width: 150px !important; }



.rma_dtail_input li em textarea {width: 300px; height: 100px;}
  .rma_dtail_input li em input[type="password"],.rma_dtail_input li em input[type="text"], .rma_dtail_input li em input[type="email"], .rma_dtail_input li em input[type="tel"], .rma_dtail_input li em select {
    width: 170px !important;
    font-size: 16px !important;
  }
.rma_dtail_input li span{font-size: 16px !important; padding-top: 7px;}
.rma_dtail_input .trch_num{font-size: 17px; width: 274px;}
.mrStatus .col-sm-3{max-width: 50%; flex: 0 0 50%; }
.mrStatus .col-sm-5{max-width: 50%; flex: 0 0 50%; }
.mrStatus .col-sm-4{max-width: 70%; flex: 0 0 70%; }



.table_contn.history.status tr td label{margin-top: 9px; }
.table_contn.history.status tr td:nth-child(4){padding: 1px;}
.table_contn.history.status tr td:nth-child(5){padding: 1px;}
.table_contn.history.status tr td:nth-child(6){padding: 1px;}
.table_contn.history.status tr td:nth-child(7){padding: 1px;}


.produ_add_btn span{font-size: 17px; }


.rma_search.method.new_ram_frm .delivery_dtail{width: 100% !important; }
.rma_search.method.new_ram_frm .delivery_dtail ul li span{padding-right: 20px; width: 39% !important;}
.rma_search.method.new_ram_frm .delivery_dtail .chek_rdio {}
.rma_search.method.new_ram_frm .delivery_dtail ul li strong{width: auto !important; padding-right: 20px;}

.rma_search.index ul li{width: 50%; float: left; }
.rma_search.index ul li span{width: 30%; float: left; }
.rma_search.index ul li:last-child em{text-align: right; width: 100%;}



.rma_dtail_input.width ul{width: 340px;}
.rma_dtail_input.width ul li span{width: 135px !important;}
.rma_dtail_input.width ul li em{width: 200px !important;}
.rma_dtail_input.width ul li input[type="text"], .rma_dtail_input.width ul li input[type="email"], .rma_dtail_input.width ul li input[type="tel"]{width: 100% !important;}
.rma_dtail_input.rma_search.fixed_width ul{width: 340px;}
.rma_dtail_input.rma_search.fixed_width ul li span{width: 135px !important;}
.rma_dtail_input.rma_search.fixed_width ul li em {width: 197px !important;}



.rma_search.index ul li{width: 50%; float: none; display: inline-block; padding: 5px 10px; }
.rma_search.index ul li:last-child{text-align: center; width: 50%; padding: 5px 10px; }
.rma_search.index ul{text-align: center; font-size: 0px;}
.rma_search.index ul li span{text-align: left; width: 100%; }
.rma_search.index ul li input[type="text"], .rma_search.index ul li select{width: 100%;}
.rma_search.index ul li em{width: 100%;}




}



@media only screen and (max-width: 767px){
  .clientH  tr td{padding-top:4px!important;}
  .SilicomH {
    width: 1500px!important;
  }
 
  .auto {
    -webkit-appearance: none;
    background: url(../images/drpArw.png) no-repeat right center;
    outline: none;
    min-width: 190px;
  }

.navbar-toggler .icon-bar { display: block; width: 22px; height: 3px; border-radius: 1px; background-color: #000;}
.navbar-toggler .icon-bar + .icon-bar { margin-top: 4px;}
.navbar-light .navbar-toggler{border-color: #000; padding: 6px 7px; outline: none; margin: 6px 0 0; border-radius: 4px !important;}

.hdr_rt ul li a {padding: 8px 15px;}
.hdr_rt ul li{font-size: 13px; line-height: 20px; }
.navbar-brand img {width: 130px;}
.hdr_rt ul li i img{max-width: 100%; width: 20px; }
.hdr_rt ul li span { padding-top: 5px;}
.navbar-brand { padding-top: 15px;}
.top_hding h1{font-size: 18px;line-height: 24px;padding: 9px 15px;}
.tab_link ul li a { padding: 8px 15px;}
.tab_link ul li{font-size: 14px; line-height: 20px;}
.rma_histry_form h2{font-size: 20px; line-height: 25px;}
.rma_histry_form h3{font-size: 18px; line-height: 24px; margin-top: 10px;}
.rma_search.issues label{font-size: 17px;}
.rma_search ul li{padding: 4px;}
.table_contn tr td a.link{font-size: 13px; line-height: 43px;}
.table_contn tr td{font-size: 13px; padding: 0px 15px; line-height: 38px;}
.table_hed .dropdown-toggle{font-size: 13px;line-height: 20px;height: 45px;background-position: right 4px center;}
.main_container { padding: 20px 15px;}
.tab_link { padding: 7px 0;}
.rma_search ul li span{font-size: 15px; }
.rma_search ul li em input[type="text"], .rma_search ul li em select{font-size: 13px; width: 168px;}
.rma_search{padding: 17px 0; }
.table_hed {width: 976px;}
.table_contn {width: 976px;}
.table_form { overflow: scroll;}


.rma_search.issues li textarea {width: 470px;height: 100px;padding: 10px;}
.file_submit { padding: 30px 0;}
.rma_search.compont {width: 100%;}

.delivery_dtail {width: 100%;}
.delivery_dtail ul li{font-size: 14px; }

.rma_search ul li input[type="submit"]{font-size: 16px;}
.hdr_rt ul li a {padding: 8px 20px !important;}
.header_sec {padding: 5px 15px;}


.file_submit ul li input[type="submit"]{font-size: 16px; line-height: 40px;}
.file_submit ul li input[type="reset"]{font-size: 16px; line-height: 40px;}
.file_input .inputfile + label{font-size: 18px;}
.chek_rdio input[type="radio"] + label{font-size: 15px;}
.chek_rdio input[type="radio"]:checked + label{font-size: 15px;}
.file_submit { padding: 40px 0;}
.rma_search.issues.input ul li span {padding: 13px;}
.rma_search.issues.input ul li em a { padding: 9px 10px;}
.tab_link.hed ul li{font-size: 18px; line-height: 25px;}


.rma_src_lft ul li span{font-size: 15px; }
.rma_src_lft ul li em input[type="text"], .rma_src_lft ul li em select{font-size: 15px; width: 150px;}
.rma_src_rt label{font-size: 15px; width: 20%;}
.rma_src_lft ul li em{width: auto; }
.rma_src_lft ul li span{width: auto; padding-right: 13px;}
.rma_src_lft {width: 100%;  float: left;}
.rma_src_rt {width: 100%;  float: left; padding-top: 20px;}
.table_contn.history tr td a.link {line-height: 44px;}
.rma_src_rt .rma_src_histry_btn a{font-size: 17px;}
.table_contn.history tr td {font-size: 16px;}
  .newRam tr td {
    font-size: 16px;
  }
.src_chek_box { width: 160px;}
.src_chek_box label{font-size: 15px !important;}
.src_chek_box.chek_rdio input[type="checkbox"] + label{background-size: 20px !important; padding-left: 27px;}
.src_chek_box.chek_rdio input[type="checkbox"]:checked + label{background-size: 20px !important; padding-left: 27px;}
.rma_src_lft ul li{width: auto; padding: 4px; padding-right: 10px; }
.ui-tooltip{width: 120px; height: 100px; font-size: 14px; line-height: 20px; }
.ui-tooltip:after{border-bottom: 10px solid; border-right: 10px solid transparent; border-left: 10px solid transparent; top: -10px; left: 10px; }



.user_frm ul li span{font-size: 17px; }
.user_btn a{font-size: 15px;width: 150px;line-height: 40px;height: 40px;}
.user_frm ul li label{font-size: 14px !important;background-size: 16px !important;padding-left: 24px !important;padding-right: 0px !important;}
.new_user .col-sm-4{max-width: 50%; flex: 0 0 50%;}
.new_user .col-sm-4:last-child{max-width: 100%; flex: 0 0 100%;}
.rma_histry_form h6 span a{font-size: 15px; width: 150px; line-height: 35px; height: 35px; }
.rma_histry_form h6 em{font-size: 17px; }
.user_btn{display: inline-block; height: auto; }
.user_frm ul li span{width: auto; padding-right: 0px;  padding-bottom: 4px;}
.user_frm ul li em{width: 100%; }
.user_frm ul li input[type="text"], .user_frm ul li input[type="email"], .user_frm ul li input[type="password"], .user_frm ul li select{width: 100% !important; }



.rma_dtail_input li em textarea {width: 300px; height: 100px;}
  .rma_dtail_input li em input[type="password"],.rma_dtail_input li em input[type="text"], .rma_dtail_input li em input[type="email"], .rma_dtail_input li em input[type="tel"], .rma_dtail_input li em select {
    width: 170px !important;
    font-size: 16px !important;
  }
.rma_dtail_input li span{font-size: 16px !important; padding-top: 7px;}
.rma_dtail_input .trch_num{font-size: 17px; width: 274px;}
.mrStatus .col-sm-3{max-width: 100%; flex: 0 0 100%; }
.mrStatus .col-sm-5{max-width: 100%; flex: 0 0 100%; }
.mrStatus .col-sm-4{max-width: 90%; flex: 0 0 90%; }
.rma_dtail_input{padding: 0px; }
.produ_add_btn ul li a{font-size: 17px; padding: 0 24px;}
.send_approv a{font-size: 17px; padding: 0 24px;}


.table_contn.history.status tr td label { margin-top: 3px; background-size: 16px !important;}
.table_contn.history.status tr td:nth-child(4){padding: 1px;}
.table_contn.history.status tr td:nth-child(5){padding: 1px;}
.table_contn.history.status tr td:nth-child(6){padding: 1px;}
.table_contn.history.status tr td:nth-child(7){padding: 1px;}


.produ_add_btn span{font-size: 18px; }


.rma_search.method.new_ram_frm .col-sm-6{max-width: 100%; flex: 0 0 100%; }
.rma_search.method.new_ram_frm .delivery_dtail{width: 100% !important; }
.rma_search.method.new_ram_frm .delivery_dtail ul li span{width: auto !important; padding-right: 10px !important;}
.rma_search.method.new_ram_frm .delivery_dtail .chek_rdio { width: auto !important;}
.rma_search.method.new_ram_frm .delivery_dtail ul li strong{width: auto !important; padding-right: 20px;}
.rma_search_brd.details .rma_search ul li:last-child input[type="text"] {width: 174px;}



.rma_search.index ul li{width: 50%; float: left; }
.rma_search.index ul li span{width: 40%; float: left; }
.rma_search.index ul li:last-child em{text-align: right; width: 100%;}
.rma_search.index ul li em input[type="text"], .rma_search.index ul li em select{width: 100%;}
.rma_search.index ul li em{width: 60%;}
.rma_search.index ul li input[type="submit"] {width: 157px;}


.rma_dtail_input.width ul{width: 340px;}
.rma_dtail_input.width ul li span{width: 135px !important;}
.rma_dtail_input.width ul li em{width: 200px !important;}
.rma_dtail_input.width ul li input[type="text"], .rma_dtail_input.width ul li input[type="email"], .rma_dtail_input.width ul li input[type="tel"]{width: 100% !important;}
.rma_dtail_input.rma_search.fixed_width ul{width: 340px;}
.rma_dtail_input.rma_search.fixed_width ul li span{width: 135px !important;}
.rma_dtail_input.rma_search.fixed_width ul li em {width: 197px !important;}
.rma_dtail_input.adrs ul {width: 336px;}
.rma_dtail_input.adrs ul li textarea{width: 100%;}
.rma_dtail_input.adrs{margin: 0px !important;}
.rma_dtail_input.adrs li{padding: 0px;}
.rma_dtail_input.rma_search.fixed_width ul li{padding: 5px 0 !important; }


.rma_src_lft ul li{width: 33.33%; float: left; }
.rma_src_lft ul li span{width: 100%; padding: 0px; padding-bottom: 6px; }



.rma_search.index ul li{width: 50%; float: none; display: inline-block; padding: 5px 10px; }
.rma_search.index ul li:last-child{text-align: center; width: 50%; padding: 5px 10px; }
.rma_search.index ul{text-align: center; font-size: 0px;}
.rma_search.index ul li span{text-align: left; width: 100%; }
.rma_search.index ul li input[type="text"], .rma_search.index ul li select{width: 100%;}
.rma_search.index ul li em{width: 100%;}




}


 
@media only screen and (max-width: 575px){



.hdr_rt ul li { font-size: 12px;}
.hdr_rt ul li a {padding: 7px 10px;}
.hdr_rt ul li i img { width: 15px;}
.navbar-brand img {width: 110px;}
.navbar-brand { padding-top: 15px;}
.rma_search ul li em input[type="text"], .rma_search ul li em select {width: 120px; padding: 0 9px;}
.rma_search ul li .datepicker{background-size: 16px; background-position: left 8px center !important;}
.rma_search ul li input[type="submit"]{font-size: 13px; width: 150px;}


.rma_search.issues li textarea { width: 100%;}
.file_submit { padding: 30px 0;}
.rma_search.compont {width: 100%;}
.rma_histry_form.component .rma_search ul li em input[type="text"], .rma_histry_form.component .rma_search ul li em input[type="email"], .rma_histry_form.component .rma_search ul li em select{width: 190px;}

.rma_histry_form.component .delivery_dtail ul li span { padding: 0px !important;}
.delivery_dtail ul li strong {  float: left; padding: 0 15px; }
.rma_histry_form.component .delivery_dtail ul li .chek_rdio{}
.rma_histry_form.component .delivery_dtail ul li .chek_rdio input[type="checkbox"]:checked + label{margin: 0px !important; font-size: 13px;
background-size: 17px !important; padding-left: 20px;}
.rma_histry_form.component .delivery_dtail ul li .chek_rdio input[type="checkbox"] + label{margin: 0px !important; font-size: 13px;
background-size: 17px !important;padding-left: 20px;}


.hdr_rt ul li a {padding: 5px 15px !important;}

.rma_src_lft ul li em input[type="text"], .rma_src_lft ul li em select{font-size: 15px; width: 150px;}
.rma_src_lft ul li em input[type="text"]{width: 120px;}
.rma_src_rt label { width: 25%;}
.rma_src_histry_btn {padding-left: 20px;}

.rma_search.index ul li{width: 100%; float: left; padding: 4px !important;}
.rma_search.index ul li span{width: 100%; float: left; }
.rma_search.index ul li em{width: 100%;}
.rma_search.index ul li:last-child em{text-align: left; }
.rma_search.index ul li input[type="submit"]{width: 174px;}


.rma_src_lft ul li{width: 50%; float: left; }
.rma_src_lft ul li em input[type="text"], .rma_src_lft ul li em select{width: 100%;}
.rma_src_lft ul li em{width: 100%;}




}


@media only screen and (max-width: 480px){


.hdr_rt ul li a { padding: 5px;}
.navbar-brand img { width: 85px;}
.hdr_rt ul li { font-size: 10px; line-height: 15px;}
.navbar-brand { padding-top: 14px;}
.top_hding h1 {font-size: 15px; line-height: 20px;  padding: 7px 10px;}
.tab_link ul li a {padding: 6px 14px;}
.tab_link ul li { font-size: 13px;  line-height: 18px;}
.rma_histry_form h2 { font-size: 18px; line-height: 24px;}
.rma_histry_form { padding: 12px;  margin-top: 15px;}
.rma_search ul li span{font-size: 14px; padding: 0px; width: 100%; margin-bottom: 7px; }
.rma_search ul li em input[type="text"], .rma_search ul li em select{width: 100%; }
.rma_search ul li{width: 100%; }
.rma_search ul li em {  width: 100%;}
.rma_search ul li:last-child { padding-left: 0px;}


.rma_histry_form.component .rma_search ul li span { width: 100%; padding-bottom: 7px; margin: 0px; }
.rma_histry_form.component .rma_search ul li em{ width: 100%;}
.chek_rdio input[type="radio"] + label{font-size: 15px; background-size: 20px; padding-left: 26px;}
.chek_rdio input[type="radio"]:checked + label{font-size: 15px; background-size: 20px; padding-left: 26px;}
.chek_rdio input[type="checkbox"] + label {font-size: 15px; background-size: 20px; padding-left: 26px;}
.chek_rdio input[type="checkbox"]:checked + label {font-size: 15px; background-size: 20px; padding-left: 26px;}
.file_submit { padding: 20px 0;}
.file_submit ul li input[type="submit"]{line-height: 35px;}
.file_submit ul li input[type="reset"]{line-height: 35px;}
.rma_search.issues.input ul { width: 255px;}
.rma_search.issues.input ul li em { width: 20%;}

.hdr_rt ul li a {padding: 5px !important;}
.navbar-brand {padding-top: 12px;}
.rma_search.issues.input h3 { margin-bottom: 17px;}
.rma_search.issues.input ul li em a img { width: 18px;}
.file_input .inputfile + label {font-size: 16px;}

.delivery_dtail ul li strong {padding: 0 9px;}
.rma_search ul li span {font-size: 12px;}
.rma_histry_form.component .delivery_dtail ul li .chek_rdio input[type="checkbox"]:checked + label {font-size: 12px; background-size: 13px !important;
padding-left: 18px;}
.rma_histry_form.component .delivery_dtail ul li .chek_rdio input[type="checkbox"] + label{font-size: 12px; background-size: 13px !important;
padding-left: 18px;}
.delivery_dtail ul li {font-size: 11px;}


.rma_src_rt label{width: 100%; padding-left: 0px;}
.rma_src_histry_btn{width: 100%; height: auto; display: inline-block; padding: 0px; margin-top: 10px; }
.src_chek_box {width: 100%;}
.table_contn.history tr td {font-size: 15px;}
  .newRam tr td {
    font-size: 15px;
  }


.new_user .col-sm-4 { max-width: 100%;  flex: 0 0 100%;}
.rma_search ul li span {font-size: 13px !important;  margin-bottom: 4px;}
.user_frm li{padding: 0px; }
.user_frm {padding: 0px;}
.user_frm .chek_rdio{padding: 0px;}
.rma_histry_form h6 span a {font-size: 14px;}
.rma_histry_form h6 em { font-size: 16px;}


.rma_dtail_input li em textarea{width: 100%;}
.rma_dtail_input ul li em{width: 100%; }
.rma_dtail_input ul li span{width: 100%; }
  .rma_dtail_input li em input[type="password"],.rma_dtail_input li em input[type="text"], .rma_dtail_input li em input[type="email"], .rma_dtail_input li em input[type="tel"], .rma_dtail_input li em select {
    width: 100% !important;
    font-size: 14px !important;
  }
.rma_dtail_input ul li span{font-size: 14px !important; padding: 0px;}
.rma_dtail_input .trch_num {font-size: 15px;  width: 240px;}
.produ_add_btn ul li a { font-size: 13px; padding: 0 16px; line-height: 35px; height: 35px;}
.send_approv a {font-size: 15px; padding: 0 24px; width: auto;}


.produ_add_btn span{width: 100%; padding-bottom: 4px; display: inline-block; }

.mrStatus .col-sm-4 {max-width: 100%; flex: 0 0 100%;}





}



@media only screen and (max-width: 480px){



.rma_dtail_input.width ul{width: 100%;}
.rma_dtail_input.width ul li span{width: 100% !important;}
.rma_dtail_input.width ul li em{width: 100% !important;}
.rma_dtail_input.width ul li input[type="text"], .rma_dtail_input.width ul li input[type="email"], .rma_dtail_input.width ul li input[type="tel"]{width: 100% !important;}
.rma_dtail_input.rma_search.fixed_width ul{width: 100%;}
.rma_dtail_input.rma_search.fixed_width ul li span{width: 100% !important;}
.rma_dtail_input.rma_search.fixed_width ul li em {width: 100% !important;}
.rma_dtail_input.adrs ul {width: 100%;}
.rma_dtail_input.adrs ul li textarea{width: 100%;}

.rma_histry_form.component .delivery_dtail ul li span {width: 100% !important;}
.delivery_dtail ul li strong{padding: 0px; padding-right: 13px; }


}
@media only screen and (min-width: 576px) {
  .modal-sm {
    max-width: 600px !important;
  }
}
 



